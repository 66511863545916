import React from 'react'
import styled from 'styled-components'

import { ContainerError } from '@/components/_layout/ContainerMessage'
import {
  FormattedMessageCentered,
  Line1,
  Line2,
} from '@/components/_shared/FormattedMessage'
import { LessonMapListItem } from '@/components/lesson/lesson/LessonMapListItem'
import { LessonDaySeparator } from '@/components/lesson/lesson/misc/LessonDaySeparator'
import { ILessonDecoratedGQLResults } from '@/services/api-graphql-lesson'
import { HTTPError } from '@/utils/api-helpers'
import { DEVICE_WIDTH } from '@/utils/constants'

import { LessonMapListLoading } from './loaders/LessonMapListLoading'

interface IProps {
  lessonListLoading: boolean
  lessonListError: HTTPError
  lessonList: ILessonDecoratedGQLResults
}

const LessonList: React.FC<IProps> = ({
  lessonListLoading,
  lessonListError,
  lessonList,
}) => {
  if (lessonListLoading) return <LessonMapListLoading />
  if (lessonListError) return <ContainerError />

  if (!lessonList?.paginationInfo.totalCount) {
    return (
      <FormattedMessageCentered
        defaultMessage={`
          <noResults>Aucun cours disponible...</noResults>
          <advice>Modifiez vos filtres pour afficher les cours disponibles</advice>
        `}
        description="LessonOnsiteMapList: no results"
        values={{
          noResults: (chunks) => <Line1>{chunks}</Line1>,
          advice: (chunks) => <Line2>{chunks}</Line2>,
        }}
      />
    )
  }

  return (
    <ListWrapper>
      {lessonList.collection.map((lesson, lessonIndex, prevLessons) => (
        <React.Fragment key={lesson.uuid}>
          {lessonIndex > 0 && (
            <LessonDaySeparator
              lesson={lesson}
              prevLesson={prevLessons[lessonIndex - 1]}
            />
          )}
          <LessonMapListItem lesson={lesson} showButton />
        </React.Fragment>
      ))}
    </ListWrapper>
  )
}

const ListWrapper = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacing.s};
`

export const LessonOnsiteMapList: React.FC<IProps> = ({
  lessonListLoading,
  lessonListError,
  lessonList,
}) => (
  <Wrapper>
    <LessonList
      lessonListLoading={lessonListLoading}
      lessonListError={lessonListError}
      lessonList={lessonList}
    />
  </Wrapper>
)

const Wrapper = styled.div`
  display: none;

  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    display: block;
    flex: 3;
    height: calc(50vw - 2 * ${({ theme }) => theme.spacing.m});
    overflow: auto;
    padding: ${({ theme }) => `0 ${theme.spacing.s}`};

    &::-webkit-scrollbar {
      width: ${({ theme }) => theme.spacing.xxs};
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.color.black};
      border-radius: ${({ theme }) => theme.spacing.xxs};
    }

    &::-webkit-scrollbar-track {
      background-color: ${({ theme }) => theme.color.greyLighter};
      border-radius: ${({ theme }) => theme.spacing.xxs};
    }
  }
`
