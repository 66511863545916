import React from 'react'
import { FormattedDate } from 'react-intl'
import styled from 'styled-components'
import { theme } from 'theme'

import { MapListItemWrapper } from '@/components/_shared/map/MapListItemWrapper'
import { LinkCover } from '@/components/_shared/style'
import { TimeAndDuration } from '@/components/_shared/TimeAndDuration'
import { LessonListItemTeacher } from '@/components/lesson/lesson/misc/LessonListItemTeacher'
import { PlaceCity } from '@/components/place/misc/PlaceCity'
import { ILessonDecorated } from '@/types/main'
import { DEVICE_WIDTH } from '@/utils/constants'
import { buildLessonSessionRoute } from '@/utils/route'

import { LessonCta } from './misc/LessonCta'
import { LessonListItemTitle } from './misc/LessonListItemTitle'

interface IProps {
  lesson: ILessonDecorated
  showButton?: boolean
  className?: string
}

export const LessonMapListItem: React.FC<IProps> = ({
  lesson,
  showButton = false,
  className,
}) => (
  <MapListItemWrapper item={lesson} className={className}>
    <LinkCover href={buildLessonSessionRoute(lesson)} />

    <ContentWrapper>
      <LessonListItemTitle lesson={lesson} />

      <DateRow>
        <TimeAndDuration startTime={lesson.startAt} duration={lesson.duration} />
        <FormattedDate
          value={lesson.startAt}
          weekday="short"
          day="numeric"
          month="short"
        />
      </DateRow>

      <PlaceCity lessonSerie={lesson} />

      <LessonListItemTeacher teacher={lesson.teacher} avatarSize={theme.spacing.l} />
    </ContentWrapper>

    {showButton && <LessonListItemMainButton lesson={lesson} scopeListing />}
  </MapListItemWrapper>
)

const ContentWrapper = styled.div`
  flex: 1;

  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    grid-template-columns: 3fr 2fr 2fr 3fr 2fr;
  }

  & > *:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing.xs};
  }
`
const DateRow = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.color.grey};
  display: flex;

  & > * {
    margin-right: ${({ theme }) => theme.spacing.s};
  }
`
const LessonListItemMainButton = styled(LessonCta)`
  z-index: 1;
`
