import React, { useEffect } from 'react'
import styled from 'styled-components'

import { MapWrapperInContext } from '@/components/_shared/map/MapWrapperInContext'
import { MapItemsContextProvider } from '@/contexts/mapItemsContext'
import { usePagination } from '@/contexts/pagination'
import { useSWRLessonOnsiteResults } from '@/hooks/swr/useSwr-lessonOnsiteResults'
import { initialMapItemsState } from '@/reducers/mapItemsReducer'
import { IPaginationInfo } from '@/services/api-graphql'
import { IGQLLessonVariables } from '@/services/api-graphql-lesson'
import { DEVICE_WIDTH } from '@/utils/constants'

import { LessonOnsiteMapList } from './LessonOnsiteMapList'
import { LessonOnsiteMapCarousel } from './map/LessonOnsiteMapCarousel'

interface IProps {
  gqlVariables?: IGQLLessonVariables
}

export const LessonOnsiteMapListPage: React.FC<IProps> = ({ gqlVariables }) => {
  const { lessonListLoading, lessonList, lessonListError } =
    useSWRLessonOnsiteResults(gqlVariables)
  const { setPaginationInfo } = usePagination()

  useEffect(() => {
    if (lessonListLoading || lessonListError) {
      return
    }

    setPaginationInfo((prevPaginationInfo: IPaginationInfo) => ({
      ...prevPaginationInfo,
      ...lessonList.paginationInfo,
    }))
  }, [gqlVariables, lessonListLoading, lessonListError])

  return (
    <MapItemsContextProvider initialMapItemsState={initialMapItemsState}>
      <LessonListPageWrapper>
        <StyledMapWrapperInContext items={lessonList?.collection}>
          <LessonOnsiteMapCarousel />
        </StyledMapWrapperInContext>

        <LessonOnsiteMapList
          lessonListLoading={lessonListLoading}
          lessonListError={lessonListError}
          lessonList={lessonList}
        />
      </LessonListPageWrapper>
    </MapItemsContextProvider>
  )
}

const LessonListPageWrapper = styled.div`
  align-items: stretch;
  display: flex;
`
const StyledMapWrapperInContext = styled(MapWrapperInContext)`
  display: flex;
  flex: 1;
  margin: 0 -20px;

  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    flex: 5;
    margin: inherit;
  }
`
