import React from 'react'

import { LessonMapListItemSkeleton } from '@/components/lesson/lesson/loaders/LessonMapListItemSkeleton'

export const LessonMapListLoading: React.FC = () => (
  <div>
    {[...Array(12)].map((x, i) => (
      <LessonMapListItemSkeleton key={i} />
    ))}
  </div>
)
