import { isSameDay } from 'date-fns'
import React from 'react'
import { FormattedDate } from 'react-intl'
import styled from 'styled-components'

import { ILessonDecorated } from '@/types/main'

interface IProps {
  lesson: ILessonDecorated
  prevLesson: ILessonDecorated
}

export const LessonDaySeparator: React.FC<IProps> = ({ lesson, prevLesson }) => {
  if (prevLesson && isSameDay(new Date(lesson.startAt), new Date(prevLesson.startAt))) {
    return null
  }

  return (
    <Wrapper>
      <FormattedDate value={lesson.startAt} weekday="long" day="numeric" month="long" />
    </Wrapper>
  )
}
const Wrapper = styled.div`
  ${({ theme }) => theme.typo.bold}

  background-color: ${({ theme }) => theme.color.beigeLight};
  border-radius: ${({ theme }) => theme.spacing.m};
  margin-bottom: ${({ theme }) => theme.spacing.s};
  padding: ${({ theme }) => theme.spacing.xs} ${({ theme }) => theme.spacing.s};
  width: 100%;

  &:not(:first-child) {
    margin-top: ${({ theme }) => theme.spacing.s};
  }
`
