import React from 'react'

import { LessonListItemSkeletonMobile } from './LessonListItemSkeletonMobile'
import { LessonMapListItemSkeletonDesktop } from './LessonMapListItemSkeletonDesktop'

export const LessonMapListItemSkeleton: React.FC = () => (
  <>
    <LessonMapListItemSkeletonDesktop />
    <LessonListItemSkeletonMobile />
  </>
)
