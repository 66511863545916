import { useRouter } from 'next/router'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled, { css } from 'styled-components'
import { theme } from 'theme'

import { CustomButton, CustomButtonLink } from '@/components/_custom/CustomButton'
import { ListIcon } from '@/components/_svg/icons/ListIcon'
import { MapIcon } from '@/components/_svg/icons/MapIcon'
import { DEVICE_WIDTH } from '@/utils/constants'
import { buildLessonExploreStudioRoute } from '@/utils/route'

export const LessonExploreRenderSwitcher: React.FC = () => {
  const router = useRouter()

  const { map: mapParam, ...otherParams } = router.query

  if (mapParam) {
    return (
      <Wrapper>
        <StyledListingButtonLink
          href={buildLessonExploreStudioRoute(
            router.query.areaSlug as string,
            otherParams
          )}
          color={theme.color.mandarine}
          shallow
        >
          <StyledListIcon />
          <FormattedMessage
            defaultMessage="Liste"
            description="LessonExploreRenderSwitcher: list"
          />
        </StyledListingButtonLink>

        <StyledMapButton color={theme.color.mandarine} disabled>
          <StyledMapIcon $disabled />
          <FormattedMessage
            defaultMessage="Carte"
            description="LessonExploreRenderSwitcher: map"
          />
        </StyledMapButton>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <StyledListingButton color={theme.color.mandarine} disabled>
        <StyledListIcon $disabled />
        <FormattedMessage
          defaultMessage="Liste"
          description="LessonExploreRenderSwitcher: list"
        />
      </StyledListingButton>

      <StyledMapButtonLink
        href={buildLessonExploreStudioRoute(router.query.areaSlug as string, {
          ...otherParams,
          map: 1,
        })}
        color={theme.color.mandarine}
        shallow
      >
        <StyledMapIcon />
        <FormattedMessage
          defaultMessage="Carte"
          description="LessonExploreRenderSwitcher: map"
        />
      </StyledMapButtonLink>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  margin: 0 auto ${({ theme }) => theme.spacing.xs};

  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    margin: inherit;
  }
`
const buttonStyle = css`
  background-color: ${({ theme }) => theme.color.white};
  color: ${({ theme }) => theme.color.mandarine};
  gap: ${({ theme }) => theme.spacing.xxs};
  padding: 12px;

  &:hover {
    background-color: ${({ theme }) => theme.color.mandarine};
    border: 1px solid ${({ theme }) => theme.color.mandarine};

    svg {
      fill: ${({ theme }) => theme.color.white};
    }
  }
`
const listingButtonStyle = css`
  ${buttonStyle}
  border-radius: ${({ theme }) => `${theme.spacing.ms} 0 0 ${theme.spacing.ms}`};
`
const StyledListingButtonLink = styled(CustomButtonLink)`
  ${listingButtonStyle}
`
const StyledListingButton = styled(CustomButton)`
  ${listingButtonStyle}

  &:disabled {
    background-color: ${({ theme }) => theme.color.mandarine};
  }
`
const mapButtonStyle = css`
  ${buttonStyle}
  border-radius: ${({ theme }) => `0 ${theme.spacing.ms} ${theme.spacing.ms} 0`};
`
const StyledMapButtonLink = styled(CustomButtonLink)`
  ${mapButtonStyle}
`
const StyledMapButton = styled(CustomButton)`
  ${mapButtonStyle}

  &:disabled {
    background-color: ${({ theme }) => theme.color.mandarine};
  }
`
const iconStyle = css`
  height: 22px;
  width: 22px;
`
const StyledListIcon = styled(ListIcon)<{ $disabled?: boolean }>`
  ${iconStyle}

  fill: ${({ theme, $disabled }) =>
    $disabled ? theme.color.white : theme.color.mandarine};
`
const StyledMapIcon = styled(MapIcon)<{ $disabled?: boolean }>`
  ${iconStyle}

  fill: ${({ theme, $disabled }) =>
    $disabled ? theme.color.white : theme.color.mandarine};
`
