import { GetStaticPaths, GetStaticProps } from 'next'
import { useRouter } from 'next/router'
import React, { ReactElement, useEffect } from 'react'

import { AutoBreadcrumb } from '@/components/_layout/AutoBreadcrumb'
import { AutoSeo } from '@/components/_layout/AutoSeo'
import { MainLayout } from '@/components/_layout/MainLayout'
import { PromoBannerSlice } from '@/components/_shared/_slice/PromoBannerSlice'
import { LessonAreaListSelectorSlice } from '@/components/lesson/_slice/LessonAreaListSelectorSlice'
import { LessonListPaginatedSlice } from '@/components/lesson/_slice/LessonListPaginatedSlice'
import { LessonAreaListSentenceDropdown } from '@/components/lesson/lesson/LessonAreaListSentenceDropdown'
import { LessonExploreRenderSwitcher } from '@/components/lesson/lesson/LessonExploreRenderSwitcher'
import { LessonListFilters } from '@/components/lesson/lesson/LessonListFilters'
import { LessonOnsiteListPage } from '@/components/lesson/lesson/LessonOnsiteListPage'
import { LessonOnsiteMapListPage } from '@/components/lesson/lesson/LessonOnsiteMapListPage'
import { SurveyFeedbackListCarouselSlice } from '@/components/survey/_slice/SurveyFeedbackListCarouselSlice'
import { LessonFiltersContextProvider } from '@/contexts/lessonFilters'
import { SWR_KEY__LESSON_ONSITE_RESULTS } from '@/hooks/swr/useSwr-lessonOnsiteResults'
import { SWR_KEY__PLACE_RESULTS } from '@/hooks/swr/useSwr-placeResults'
import {
  PROPERTY_KEY_SLUGS_1,
  SWR_KEY__PROPERTY_RESULTS,
} from '@/hooks/swr/useSwr-propertyResults'
import { SWR_KEY__TEACHER_RESULTS } from '@/hooks/swr/useSwr-teacherResults'
import { INextPageWithLayout } from '@/pages/_app'
import { ILessonFiltersState, lessonFiltersInitialState } from '@/reducers/lessonFilters'
import {
  buildGqlVariablesFromLessonFilters,
  getLessonOnsiteResults,
} from '@/services/api-graphql-lesson'
import { getPlaceResults } from '@/services/api-graphql-place'
import {
  getPropertyResults,
  getPropertyValueResult,
  IPropertyResultsVariables,
} from '@/services/api-graphql-property'
import {
  getTeacherResults,
  ITeacherResultsVariables,
} from '@/services/api-graphql-teacher'
import { findStudioGTMEvent } from '@/services/gtm'
import { IAreaKey, IPropertyValueArea } from '@/types/main'
import { STUDIO_LESSONS_FEEDBACKS } from '@/utils/feedbacks'
import { serializeSwrApiKey } from '@/utils/swr'

interface IProps {
  initialLessonFiltersState: ILessonFiltersState
  area: IPropertyValueArea
}

const ExploreStudioAreaPage: INextPageWithLayout<IProps> = ({
  initialLessonFiltersState,
  area,
}) => {
  const router = useRouter()

  useEffect(() => {
    findStudioGTMEvent(area.title)
  }, [area])

  return (
    <>
      <AutoSeo area={area} />
      <AutoBreadcrumb area={area} />

      <LessonFiltersContextProvider initialLessonFiltersState={initialLessonFiltersState}>
        <LessonAreaListSentenceDropdown />

        <LessonListPaginatedSlice
          pageComponent={
            router.query.map ? <LessonOnsiteMapListPage /> : <LessonOnsiteListPage />
          }
          lessonListFilterComponent={
            <LessonListFilters
              renderSwitcherComponent={<LessonExploreRenderSwitcher />}
              filterRenderersLessonOpts={{
                mainFilterKeys: [
                  'disciplineIds',
                  router.query.map ? 'durationIds' : 'postalCodes',
                  'teacherIds',
                ],
              }}
            />
          }
        />
      </LessonFiltersContextProvider>

      <PromoBannerSlice />

      <SurveyFeedbackListCarouselSlice
        feedbacks={STUDIO_LESSONS_FEEDBACKS[(area.parent ?? area).slug ?? 'all']}
      />

      <LessonAreaListSelectorSlice />
    </>
  )
}

export const getStaticPaths: GetStaticPaths = async () => {
  // Limit prerender to only some area
  const areaSlugs = ['paris']

  return {
    paths: areaSlugs.map((areaSlug) => ({ params: { areaSlug } })),
    fallback: 'blocking',
  }
}

export const getStaticProps: GetStaticProps = async ({ params }) => {
  const areaSlug = params.areaSlug.toString()
  const area = await getPropertyValueResult<IPropertyValueArea>({
    propKeySlug: 'area',
    slug: areaSlug,
  })

  if (!area) {
    return {
      notFound: true,
      revalidate: 60, // 1mn
    }
  }

  const lessonFiltersStateWithArea = {
    ...lessonFiltersInitialState,
    ...(area && {
      areaKey: (area.parent ?? area).slug as IAreaKey,
      ...(area.misc?.postalCode && { postalCodes: [area.misc.postalCode] }),
    }),
  } as ILessonFiltersState

  const gqlVariablesLessonList = buildGqlVariablesFromLessonFilters(
    lessonFiltersStateWithArea
  )
  const initialLessonList = await getLessonOnsiteResults(gqlVariablesLessonList)

  const gqlVariablesPropertyList = {
    slugs: PROPERTY_KEY_SLUGS_1,
  } as IPropertyResultsVariables
  const initialPropertyList = await getPropertyResults(gqlVariablesPropertyList)

  const areaKey = lessonFiltersStateWithArea.areaKey
  const gqlVariablesTeacherList = {
    gqlQuery: 'LessonOnsite',
    itemsPerPage: 200,
    areaKey,
  } as ITeacherResultsVariables
  const initialTeacherList = await getTeacherResults(gqlVariablesTeacherList)

  const gqlVariablesPlaceList = {
    areaKey,
    itemsPerPage: 400,
  }
  const initialPlaceList = await getPlaceResults(gqlVariablesPlaceList)

  return {
    props: {
      area,
      initialLessonFiltersState: lessonFiltersStateWithArea,
      swrFallback: {
        [serializeSwrApiKey(SWR_KEY__LESSON_ONSITE_RESULTS, gqlVariablesLessonList)]:
          initialLessonList,
        [serializeSwrApiKey(SWR_KEY__PROPERTY_RESULTS, gqlVariablesPropertyList)]:
          initialPropertyList,
        [serializeSwrApiKey(SWR_KEY__TEACHER_RESULTS, gqlVariablesTeacherList)]:
          initialTeacherList,
        [serializeSwrApiKey(SWR_KEY__PLACE_RESULTS, gqlVariablesPlaceList)]:
          initialPlaceList,
      },
    },
    revalidate: 1800, // 30mn
  }
}

ExploreStudioAreaPage.getLayout = (page: ReactElement) => (
  <MainLayout disableAutoSeo disableAutoBreadcrumb>
    {page}
  </MainLayout>
)

// eslint-disable-next-line import/no-default-export
export default ExploreStudioAreaPage
