import React, { useEffect } from 'react'
import styled from 'styled-components'

import { ContainerError } from '@/components/_layout/ContainerMessage'
import { PromoBannerSlice } from '@/components/_shared/_slice/PromoBannerSlice'
import {
  FormattedMessageCentered,
  Line1,
  Line2,
} from '@/components/_shared/FormattedMessage'
import { usePagination } from '@/contexts/pagination'
import { useSWRLessonOnsiteResults } from '@/hooks/swr/useSwr-lessonOnsiteResults'
import { IPaginationInfo } from '@/services/api-graphql'
import { IGQLLessonVariables } from '@/services/api-graphql-lesson'
import { DEVICE_WIDTH } from '@/utils/constants'

import { LessonListItem } from './LessonListItem'
import { LessonListPageLoading } from './loaders/LessonListPageLoading'
import { LessonDaySeparator } from './misc/LessonDaySeparator'

interface IProps {
  gqlVariables?: IGQLLessonVariables
}

export const LessonOnsiteListPage: React.FC<IProps> = ({ gqlVariables }) => {
  const { lessonListLoading, lessonList, lessonListError } =
    useSWRLessonOnsiteResults(gqlVariables)
  const { setPaginationInfo } = usePagination()

  useEffect(() => {
    if (lessonListLoading || lessonListError) {
      return
    }

    setPaginationInfo((prevPaginationInfo: IPaginationInfo) => ({
      ...prevPaginationInfo,
      ...lessonList.paginationInfo,
    }))
  }, [gqlVariables, lessonListLoading, lessonListError])

  if (lessonListLoading) return <LessonListPageLoading />
  if (lessonListError) return <ContainerError />

  if (!lessonList.paginationInfo.totalCount) {
    return (
      <FormattedMessageCentered
        defaultMessage={`
          <noResults>Aucun cours disponible...</noResults>
          <advice>Modifiez vos filtres pour afficher les cours disponibles</advice>
        `}
        description="LessonOnsiteListPage: no results"
        values={{
          noResults: (chunks) => <Line1>{chunks}</Line1>,
          advice: (chunks) => <Line2>{chunks}</Line2>,
        }}
      />
    )
  }

  return (
    <LessonListPageWrapper>
      <LessonListWrapper>
        {lessonList.collection.map((lesson, lessonIndex, prevLessons) => (
          <React.Fragment key={lesson.uuid}>
            {lessonIndex === 10 && <PromoBannerSlice areaKey={gqlVariables.areaKey} />}
            {lessonIndex > 0 && (
              <LessonDaySeparator
                lesson={lesson}
                prevLesson={prevLessons[lessonIndex - 1]}
              />
            )}
            <LessonListItem lesson={lesson} />
          </React.Fragment>
        ))}
      </LessonListWrapper>
    </LessonListPageWrapper>
  )
}

const LessonListPageWrapper = styled.div`
  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    align-items: stretch;
    display: flex;
  }
`
const LessonListWrapper = styled.div`
  width: 100%;
`
