import React, { JSXElementConstructor, ReactElement } from 'react'
import styled from 'styled-components'

import { Container } from '@/components/_layout/Container'
import { CancelBookingModal } from '@/components/booking/CancelBookingModal'
import { CalendarSelector } from '@/components/lesson/lesson/filters/CalendarSelector'
import { LessonListPaginated } from '@/components/lesson/lesson/LessonListPaginated'
import { LessonBookingContextProvider } from '@/contexts/lessonBooking'
import { PaginationContextProvider } from '@/contexts/pagination'
import { DEVICE_WIDTH } from '@/utils/constants'

interface IProps {
  pageComponent: ReactElement<any, string | JSXElementConstructor<any>>
  lessonListFilterComponent: ReactElement<any, string | JSXElementConstructor<any>>
}

export const LessonListPaginatedSlice: React.FC<IProps> = ({
  pageComponent,
  lessonListFilterComponent,
}) => (
  <LessonBookingContextProvider>
    <PaginationContextProvider>
      <Container>
        {lessonListFilterComponent}
        <StyledCalendarSelector />

        <LessonListPaginated pageComponent={pageComponent} />
      </Container>
    </PaginationContextProvider>

    <CancelBookingModal />
  </LessonBookingContextProvider>
)

const StyledCalendarSelector = styled(CalendarSelector)`
  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    display: none;
  }
`
