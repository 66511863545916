import React from 'react'
import styled from 'styled-components'

import { Carousel } from '@/components/_shared/Carousel'
import { LessonMapListItem } from '@/components/lesson/lesson/LessonMapListItem'
import { useMapItemsState } from '@/contexts/mapItemsContext'
import { ILessonDecorated } from '@/types/main'

export const LessonOnsiteMapCarousel: React.FC = () => {
  const {
    mapItemsState: { selectedMapItems },
  } = useMapItemsState<ILessonDecorated>()

  if (!selectedMapItems) return null

  if (selectedMapItems.length === 1) {
    return (
      <StyledMapLessonListItem
        key={selectedMapItems[0].uuid}
        lesson={selectedMapItems[0]}
      />
    )
  }

  return (
    <StyledCarousel nbVisibleItems={2} hideNavButtons>
      {selectedMapItems.map((lesson: ILessonDecorated) => (
        <LessonMapListItem key={lesson.uuid} lesson={lesson} />
      ))}
    </StyledCarousel>
  )
}

const StyledCarousel = styled(Carousel)`
  bottom: 0;
  display: block;
  overflow-x: auto;
  position: absolute;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
`
const StyledMapLessonListItem = styled(LessonMapListItem)`
  bottom: 0;
  display: block;
  left: ${({ theme }) => theme.spacing.s};
  overflow-x: auto;
  position: absolute;
  right: ${({ theme }) => theme.spacing.s};
  width: 60%;

  &::-webkit-scrollbar {
    display: none;
  }
`
