import React from 'react'
import ContentLoader from 'react-content-loader'
import styled from 'styled-components'

import { DEVICE_WIDTH } from '@/utils/constants'

const LINE_WIDTH = 160
const LINE_HEIGHT = 24
const MARGIN = 8
const BUTTON_HEIGHT = 48
const ITEM_HEIGHT = LINE_HEIGHT * 2 + MARGIN * 3

export const LessonMapListItemSkeletonDesktop: React.FC = () => (
  <Wrapper>
    <Content>
      <TitleSkeleton />
      <DateSkeleton />
      <PlaceSkeleton />
      <TeacherSkeleton />
    </Content>

    <ButtonSkeleton />
  </Wrapper>
)

const TitleSkeleton: React.FC = () => (
  <StyledContentLoader uniqueKey="TitleSkeleton" $height={LINE_HEIGHT}>
    <rect
      x={0}
      y={0}
      rx={LINE_HEIGHT / 2}
      ry={LINE_HEIGHT / 2}
      width={LINE_WIDTH}
      height={LINE_HEIGHT}
    />
  </StyledContentLoader>
)

const DateSkeleton: React.FC = () => (
  <StyledContentLoader uniqueKey="DateSkeleton" $height={LINE_HEIGHT}>
    <rect
      x={0}
      y={0}
      rx={LINE_HEIGHT / 2}
      ry={LINE_HEIGHT / 2}
      width={LINE_WIDTH / 2}
      height={LINE_HEIGHT}
    />
    <rect
      x={LINE_WIDTH / 2 + MARGIN}
      y={0}
      rx={LINE_HEIGHT / 2}
      ry={LINE_HEIGHT / 2}
      width={LINE_WIDTH}
      height={LINE_HEIGHT}
    />
  </StyledContentLoader>
)

const PlaceSkeleton: React.FC = () => (
  <StyledContentLoader uniqueKey="PlaceSkeleton" $height={LINE_HEIGHT}>
    <rect
      x={0}
      y={0}
      rx={LINE_HEIGHT / 2}
      ry={LINE_HEIGHT / 2}
      width={LINE_WIDTH / 2}
      height={LINE_HEIGHT}
    />
  </StyledContentLoader>
)

const TeacherSkeleton: React.FC = () => (
  <StyledContentLoader uniqueKey="TeacherSkeleton" $height={LINE_HEIGHT * 2}>
    <circle cx={ITEM_HEIGHT / 3} cy={ITEM_HEIGHT / 3} r={ITEM_HEIGHT / 3} />
    <rect
      x={LINE_HEIGHT * 2 + MARGIN}
      y={LINE_HEIGHT / 2}
      rx={LINE_HEIGHT / 2}
      ry={LINE_HEIGHT / 2}
      width={LINE_WIDTH / 2}
      height={LINE_HEIGHT}
    />
  </StyledContentLoader>
)

const ButtonSkeleton: React.FC = () => (
  <ButtonContentLoader uniqueKey="ButtonSkeleton" $height={BUTTON_HEIGHT}>
    <rect
      x={0}
      y={0}
      rx={BUTTON_HEIGHT / 2}
      ry={BUTTON_HEIGHT / 2}
      width={BUTTON_HEIGHT * 3}
      height={BUTTON_HEIGHT}
    />
  </ButtonContentLoader>
)

const Wrapper = styled.div`
  display: none;

  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    align-items: center;
    border: 0;
    border-radius: ${({ theme }) => theme.spacing.s};
    box-shadow: ${({ theme }) => theme.boxShadow.neutral};
    display: flex;
    justify-content: space-between;
    margin: ${({ theme }) => theme.spacing.s} 0;
    padding: ${({ theme }) => `${theme.spacing.s} ${theme.spacing.ms}`};
  }
`
const Content = styled.div`
  flex: 1;

  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    margin-right: ${({ theme }) => theme.spacing.s};
  }
`
const StyledContentLoader = styled(ContentLoader)<{ $height: number }>`
  height: ${({ $height }) => $height}px;
`
const ButtonContentLoader = styled(StyledContentLoader)<{ $height: number }>`
  height: ${({ $height }) => $height}px;
  width: ${({ $height }) => $height * 3}px;
`
